<template>
    <div class="contianer p-md-5 p-3">
        <PageLoader :isLoading="processingForm" />
        <directory-listing-header
            v-if="!formSubmitted"
            title="Post New Directory Listing"
            :description="
                'Fill up the details to post your directory.<br /><span class=\'small text-secondary\'> Fields marked with \'*\' are mandatory.</span>'
            "
            hidePostBtn
            titleCentered
        />

        <div class="form-submitted-message" v-if="formSubmitted">
            <h5 class="text-center">Directory Submitted Successfully</h5>
            <div class="text-center">
                Your directory has been submitted to the admin for approval.
                <br />It will be live once its approved Thank you!
                <div class="text-center p">
                    <router-link
                        :to="{ name: 'directory_listing' }"
                        class="d-inline primary-button btn-success"
                    >
                        Back to Directories
                    </router-link>
                </div>
            </div>
        </div>

        <div v-if="!formSubmitted">
            <form
                class="form directory-form"
                @submit.prevent="submitDirectoryEmail"
                v-if="showOtpForm"
            >
                <div class="form-group-header">
                    Email Verification For New Directory
                </div>

                <p class="text-secondary small">
                    It is first necessary to verify your email through OTP to post a new directory.
                    Please enter your email below.
                </p>

                <div class="form-group">
                    <label>Enter Email Address*</label>
                    <input type="text" class="form-control" v-model="email" />
                    <FormError :title="formErrors.email" />
                </div>
                <div class="form-group" v-if="otpSent">
                    <label>Enter OTP sent to your mail*</label>
                    <input type="text" class="form-control" v-model="otp" />
                    <FormError :title="formErrors.otp" />
                </div>
                <button type="submit" class="primary-button" v-if="!otpSent">
                    Send OTP Confirmation
                </button>
                <button type="submit" class="primary-button" v-else>
                    Verify Email
                </button>
            </form>
        </div>

        <form
            class="form directory-form"
            @submit.prevent="submitDirectory"
            v-if="!formSubmitted && !showOtpForm"
            enctype="multipart/form-data"
        >
            <div class="form-group-header">
                Business Information
            </div>

            <div class="form-group">
                <label>Business Name*</label>
                <input type="text" class="form-control" v-model="businessName" />
                <FormError :title="formErrors.businessName" />
            </div>
            <div class="form-group">
                <label>Business Description*</label>
                <textarea
                    v-model="businessDescription"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                ></textarea>
                <div class="description-limit">
                    {{ businessDescription.length }} / {{ businessDescriptionMaxLength }}
                </div>
                <FormError :title="formErrors.businessDescription" />
            </div>
            <div class="form-group">
                <label>Service Category*</label>
                <select v-model="serviceCategoryId" class="form-control">
                    <option disabled value="" selected>
                        Select Service Category
                    </option>
                    <option
                        v-for="serviceCategory in serviceCategories"
                        :value="serviceCategory.id"
                        :key="serviceCategory.id"
                        >{{ serviceCategory.name }}</option
                    >
                </select>
                <FormError :title="formErrors.serviceCategoryId" />
            </div>

            <div class="form-group">
                <label>Business Photo (or thumbnail)*</label>
                <ImageUploader :single="true" @update="businessPhoto = $event" />
                <FormError :title="formErrors.businessPhoto" />
            </div>

            <div class="form-group" v-if="showServiceCategoryOther">
                <label>Enter Service Category (Other)*</label>
                <input type="text" class="form-control" v-model="serviceCategoryOther" />
                <FormError :title="formErrors.serviceCategoryOther" />
            </div>

            <div class="form-group-header">
                Contact Details
            </div>

            <div class="form-group">
                <label>Full Name*</label>
                <input type="text" class="form-control" v-model="name" />
                <FormError :title="formErrors.name" />
            </div>
            <div class="form-group">
                <label>Phone*</label>
                <input type="text" class="form-control" v-model="phone" />
                <FormError :title="formErrors.phone" />
            </div>
            <div class="form-group">
                <label>Email (Verified)*</label>
                <input type="text" class="form-control" v-model="email" readonly />
                <FormError :title="formErrors.email" />
            </div>

            <div class="form-group-header">
                Social Links
            </div>

            <div class="form-group">
                <label>Website</label>
                <input type="text" class="form-control" v-model="website" />
                <FormError :title="formErrors.website" />
            </div>
            <div class="form-group">
                <label>Facebook Link</label>
                <input type="text" class="form-control" v-model="facebookLink" />
                <FormError :title="formErrors.facebookLink" />
            </div>
            <div class="form-group">
                <label>LinkedIn Link</label>
                <input type="text" class="form-control" v-model="linkedinLink" />
                <FormError :title="formErrors.linkedinLink" />
            </div>
            <button type="submit" class="primary-button mt-5">
                Submit Directory
            </button>
        </form>
    </div>
</template>

<script>
import DirectoryListingHeader from "./DirectoryListingHeader.vue"
import FormError from "../utils/FormError.vue"
import ImageUploader from "../utils/ImageUploader.vue"
import PageLoader from "../Loader.vue"
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"

export default {
    components: { DirectoryListingHeader, FormError, PageLoader, ImageUploader },
    data() {
        return {
            formErrors: {},
            name: "",
            businessName: "",
            businessPhoto: null,
            phone: "",
            email: "",
            website: "",
            serviceCategoryId: "",
            serviceCategoryOther: "",
            showServiceCategoryOther: false,
            businessDescription: "",
            facebookLink: "",
            linkedinLink: "",
            formSubmitted: false,
            processingForm: false,
            emailVerified: false,
            otp: "",
            otpLength: 4,
            otpSent: false,
            showOtpForm: true,
            serviceCategories: [],
            otherCategory: {},
            startValidation: false,
            businessDescriptionMaxLength: 2000,
            uuid: "",
        }
    },
    mounted() {
        scrollTop()
        this.fetchCategories()
    },
    watch: {
        businessName() {
            this.validateForm()
        },
        serviceCategoryOther() {
            this.validateForm()
        },
        serviceCategoryId(e) {
            const serviceCategory = this.serviceCategories.find((category) => category.id === e)
            if (serviceCategory.name === this.otherCategory.name) {
                this.showServiceCategoryOther = true
            } else {
                this.showServiceCategoryOther = false
            }
            this.validateForm()
        },
        businessDescription(e) {
            if (e.length > this.businessDescriptionMaxLength) {
                this.businessDescription = e.slice(0, this.businessDescriptionMaxLength)
            }
            this.validateForm()
        },
        name() {
            this.validateForm()
        },
        email() {
            this.validateForm()
        },
        phone() {
            this.validateForm()
        },
        website() {
            this.validateForm()
        },
        facebookLink() {
            this.validateForm()
        },
        linkedinLink() {
            this.validateForm()
        },
        otp() {
            this.validateForm()
        },
    },
    methods: {
        async fetchCategories() {
            const res = await request.get("/directories/categories?sorted=true")
            let serviceCategories = res.data.result
            let otherCategory = {}
            serviceCategories = serviceCategories.filter((category) => {
                if (category.name === "Miscellaneous") {
                    otherCategory = category
                    return false
                }
                return true
            })
            this.otherCategory = {
                ...otherCategory,
                name: "Other",
            }
            serviceCategories.push({ ...this.otherCategory })
            this.serviceCategories = serviceCategories
        },
        validateForm() {
            this.formErrors = {}
            if (!this.startValidation) return

            if (!this.email) {
                this.formErrors.email = "Email address is required."
            } else {
                let emailValidation = this.validateEmail()
                if (emailValidation.invalid) {
                    this.formErrors.email = emailValidation.message
                }
                console.log("email validation", emailValidation)
            }

            if (this.showOtpForm) {
                // verification for otp form

                if (this.otpSent) {
                    if (!this.otp) {
                        this.formErrors.otp = "OTP is required."
                    } else {
                        let otpValidation = this.validateOtp()
                        if (otpValidation.invalid) {
                            this.formErrors.otp = otpValidation.message
                        }
                    }
                }
            } else {
                // verfication for directory submissions
                // contact details

                if (!this.name) {
                    this.formErrors.name = "Full name is required."
                }
                if (!this.phone) {
                    this.formErrors.phone = "Phone number is required."
                } else {
                    let phoneValidation = this.validatePhone()
                    if (phoneValidation.invalid) {
                        this.formErrors.phone = phoneValidation.message
                    }
                }

                // business details

                if (!this.businessName) {
                    this.formErrors.businessName = "Business name is required."
                }
                if (!this.businessDescription) {
                    this.formErrors.businessDescription = "Business description is required."
                } else if (!this.businessDescription >= this.businessDescriptionMaxLength) {
                    this.formErrors.businessDescription =
                        "Business desription must be within 2000 characters."
                }
                if (!this.businessPhoto) {
                    this.formErrors.businessPhoto = "Business photo is required."
                }

                if (!this.serviceCategoryId) {
                    this.formErrors.serviceCategoryId = "Service Category is required."
                } else if (
                    this.serviceCategoryId === this.otherCategory.id &&
                    !this.serviceCategoryOther
                ) {
                    this.formErrors.serviceCategoryOther = "Please specify the service category."
                }

                // social link details

                // if (!this.website) {
                //     this.formErrors.website = "Website link is required."
                // }
                // if (!this.facebookLink) {
                //     this.formErrors.facebookLink = "Facebook link is required."
                // }
                // if (!this.linkedinLink) {
                //     this.formErrors.linkedinLink = "LinkedIn link is required."
                // }
            }

            if (Object.keys(this.formErrors).length) {
                // form is not valid. errors are present
                return false
            }

            // form is valid
            return true
        },
        validatePhone() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.phone.length; i++) {
                if (!(this.phone[i] >= "0" && this.phone[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let message
            if (res == false) message = "Only Digits allowed without spaces"
            else if (digitCount != 10) message = "Phone number must be 10 digits"

            if (res && digitCount === 10) {
                return { invalid: false }
            }
            return {
                invalid: true,
                message,
            }
        },
        validateEmail() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let message
            if (res === false) message = "Email is not valid."

            if (res) {
                return { invalid: false }
            }
            return {
                invalid: true,
                message,
            }
        },
        validateOtp() {
            let otpDigits = /^[0-9]*$/.test(this.otp)
            let res = this.otp.length === this.otpLength && otpDigits
            let message
            if (res === false) message = "OTP is not valid."

            if (res) {
                return { invalid: false }
            }
            return {
                invalid: true,
                message,
            }
        },

        async submitDirectory() {
            this.startValidation = true
            if (this.processingForm) return
            if (!this.emailVerified) {
                // scrollTop()
                return this.$toasted.error("Please verify your email before submitting the form!")
            }
            if (!this.validateForm()) {
                // scrollTop()
                return this.$toasted.error("Please correct the errors before submitting the form!")
            }

            this.processingForm = true
            try {
                const directory = new FormData()

                directory.append("business_name", this.businessName)
                directory.append("business_description", this.businessDescription)
                directory.append("business_photo", this.businessPhoto)
                directory.append("service_category_id", this.serviceCategoryId)
                directory.append("name", this.name)
                directory.append("phone", this.phone)
                directory.append("email", this.email)
                directory.append("website", this.website)
                directory.append("facebook_link", this.facebookLink)
                directory.append("linkedin_link", this.linkedinLink)
                directory.append("uuid", this.uuid)

                // add the custom category field if the service category is other.
                if (this.serviceCategoryId === this.otherCategory.id) {
                    directory.service_category_other = this.serviceCategoryOther
                }
                const res = await request.post("/directories", directory)
                this.formSubmitted = true
                scrollTop()
                this.$toasted.success("Directory Submitted Successfully!")
            } catch (err) {
                console.log("Error in posting directory", err)
                this.$toasted.error("Sorry. Could not post directory! Try again later.")
            }
            this.processingForm = false
        },

        async submitDirectoryEmail() {
            this.startValidation = true
            if (this.processingForm) return

            if (!this.validateForm()) {
                return this.$toasted.error("Please correct the errors.")
            }
            if (!this.otpSent) {
                this.sendEmailVerificationOtp()
            } else {
                this.processingForm = true
                // verify email otp and start the form
                try {
                    await request.post("/directories/verify-otp", {
                        uuid: this.uuid,
                        otp: this.otp,
                    })
                    this.showOtpForm = false
                    this.emailVerified = true

                    // the next form will start validation after its submission.
                    this.startValidation = false

                    this.$toasted.success(
                        "OTP verified successfully. Now you can submit your directory listing."
                    )
                } catch (err) {
                    console.log("Error in verifying OTP", err)
                    const message =
                        err && err.response && err.response.data && err.response.data.message
                    if (message && err.response.data.operational) {
                        this.$toasted.error(message)
                    }
                }
                this.processingForm = false
            }
        },
        async sendEmailVerificationOtp() {
            this.processingForm = true
            try {
                const res = await request.post("/directories/send-otp", {
                    email: this.email,
                })
                this.otpSent = true
                this.uuid = res.data.result
                this.$toasted.success("OTP sent successfully. Please check your email.")
            } catch (err) {
                console.log("Error in sending verification email.")
                this.$toasted.error("Error in sending OTP. Try again later.")
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
.directory-form {
    margin: 30px auto;
    max-width: 600px;
}
.form-group-header {
    color: #888;
    font-weight: bold;
    background: rgb(255, 248, 210);
    text-align: center;
    padding: 10px 0;
    margin-bottom: 20px;
    border-top: 5px solid rgb(244, 226, 127);
    margin-top: 50px;
}
.form {
    label {
        display: block;
    }
    .description-limit {
        font-size: 0.8em;
        color: #888;
        margin-top: 5px;
    }
}

.form-submitted-message {
    background: hsl(120, 70, 90);
    padding: 20px;
    border-radius: 5px;
    font-size: 0.9em;

    .p {
        max-width: 600px;
        margin: 20px auto;
    }
}
</style>
