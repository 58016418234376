<template>
    <div class="image-dropzone" ref="dropzone">
        <div class="dropzone-hint" v-if="single">
            <span v-if="!image">
                Select or Drag your thumbnail.
            </span>
            <span v-else>Change Thumbnail</span>
        </div>
        <div class="d-flex w-100" v-if="!single">
            <div class="dropzone-hint mr-2">
                Select or Drag multiple images.
            </div>
            <div
                class="dropzone-hint ml-2 remove-all-btn"
                @click.stop="removeAllImages"
                v-if="this.images.length > 0"
            >
                Remove all
            </div>
        </div>
        <input
            ref="fileInputSingle"
            type="file"
            class="d-none"
            @input="handleFiles($event.target.files, $event.target)"
            accept="image/*"
            v-if="single"
        />
        <input
            ref="fileInputMultiple"
            type="file"
            class="d-none"
            @input="handleFiles($event.target.files, $event.target)"
            accept="image/*"
            multiple
            v-else
        />
        <transition name="fade">
            <div
                class="image-container single-image-container"
                v-if="single && image"
            >
                <div class="img-container">
                    <button
                        type="button"
                        class="image-close-btn"
                        @click.stop="removeImage"
                    >
                        x</button
                    ><img class="single-img" :src="image.image" />
                </div>
            </div>
        </transition>
        <transition-group
            name="fade"
            class="image-container multiple-image-container"
            tag="div"
        >
            <div
                class="img-container multiple-img-container"
                v-for="item of images"
                :key="item.key"
            >
                <button
                    class="image-close-btn"
                    type="button"
                    @click.stop="removeImage($event, item.key)"
                >
                    x</button
                ><img class="multiple-img" :src="item.image" />
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    props: {
        single: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            image: "",
            images: [],
            imageFile: null,
            imageFiles: [],
        }
    },
    watch: {
        imageFile() {
            console.log("image file changed", this.imageFile)
            this.$emit("update", this.imageFile)
        },
        imageFiles() {
            console.log("image file changed", this.imageFiles)
            this.$emit("update", this.imageFiles)
        },
    },
    methods: {
        dragLeave(e) {
            const dropzone = this.$refs.dropzone
            dropzone.classList.remove("active")
            e.preventDefault()
            console.log("drag leave")
        },
        dragOver(e) {
            const dropzone = this.$refs.dropzone
            dropzone.classList.add("active")
            e.preventDefault()
            console.log("drag end")
        },
        drop(e) {
            e.preventDefault()
            const dropzone = this.$refs.dropzone
            dropzone.classList.remove("active")
            this.handleFiles(e.dataTransfer.files)
        },
        openFilePicker() {
            if (this.single) {
                this.$refs.fileInputSingle.click()
            } else {
                this.$refs.fileInputMultiple.click()
            }
        },
        async handleFiles(files, fileInput) {
            let images = []
            this.imageFile = files[0]

            if (!this.single) {
                images = [...this.images]
                this.imageFiles = [...this.imageFiles, ...files]
            }
            for (let file of files) {
                images = [
                    ...images,
                    {
                        image: await this.getFileUrl(file),
                        key: Math.random().toString(),
                    },
                ]
            }
            if (this.single && images.length > 0) {
                this.image = ""
                setTimeout(() => (this.image = images[0]), 0)
            } else {
                this.images = images
            }
            fileInput.value = ""
        },
        getFileUrl(file) {
            const promise = (resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.onerror = () => {
                    reject("Error in reading file")
                }
            }
            return new Promise(promise)
        },
        removeImage(e, key) {
            e.stopPropagation()
            if (this.single) {
                this.image = ""
                this.imageFile = null
            } else {
                const index = this.images.findIndex((img) => img.key === key)
                const imageFiles = []
                for (let i = 0; i < this.imageFiles.length; i++) {
                    if (index !== i) imageFiles.push(this.imageFiles[i])
                }
                this.imageFiles = imageFiles
                this.images.splice(index, 1)
            }
        },
        removeAllImages() {
            this.images = []
            this.imageFiles = []
        },
    },
    mounted() {
        const dropzone = this.$refs.dropzone
        dropzone.addEventListener("click", this.openFilePicker, false)
        dropzone.addEventListener("dragleave", this.dragLeave, false)
        dropzone.addEventListener("dragover", this.dragOver, false)
        dropzone.addEventListener("drop", this.drop, false)
    },
}
</script>

<style lang="scss" scoped>
.image-dropzone {
    font-size: 0.8em;
    margin: 10px auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    padding: 15px 30px;
    background: #eee;
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid #fff;

    &.active,
    &:hover {
        border: 2px dashed #333;
        background: #ccc;
        .dropzone-hint {
            background: #eee;
        }
    }
    .dropzone-hint {
        transition: 0.3s;
        background: #ccc;
        text-align: center;
        padding: 10px;
        width: 100%;
        &:hover {
            background: rgb(196, 224, 252);
        }
        &.remove-all-btn {
            &:hover {
                background: rgb(252, 203, 203);
            }
        }
    }
}

.image-container {
    width: 100%;
    margin: 0;
    padding: 0;

    .img-container {
        position: relative;
        z-index: 10;
        margin-top: 30px;

        .image-close-btn {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 40px;
            height: 40px;
            background-color: red;
            color: white;
            border-radius: 50%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            transition: 0.3s;
            cursor: pointer;
            border: 1px solid red;

            &:hover {
                border: 1px solid white;
                background: rgb(40, 40, 40);
            }
        }
    }

    &.single-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .single-img {
            max-width: 400px;
            width: 100%;
            height: auto;
            border-radius: 10px;
            // object-fit: contain;
        }
    }
    &.multiple-image-container {
        display: flex;
        flex-flow: row wrap;
        .multiple-img-container {
            margin-right: 20px;
            .multiple-img {
                object-fit: cover;
                width: 150px;
                height: 150px;
                border-radius: 10px;
            }
        }
    }
}

/* TRANSITIONS */

.fade-enter-to {
    opacity: 1;
    transform: translateY(0px);
}
.fade-enter-active,
.fade-leave-active {
    transition: 0.3s;
}
.fade-enter,
.fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
.fade-move {
    transition: 0.3s;
}
</style>
