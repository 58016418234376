<template>
    <div v-if="title" class="my-2">
        <span class="text-danger small">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
    },
}
</script>

<style></style>
